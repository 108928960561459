import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'admin',
    loadChildren: () =>
      import('src/app/backend/backend.module').then((m) => m.BackendModule),
  },
  {
    path: 'agent',
    loadChildren: () =>
      import('./agent-whatsapp/agent-whatsapp.module').then(
        (m) => m.AgentWhatsappModule
      ),
  },
  {
    path: 'wagent',
    loadChildren: () => import('./agent-web/agent-web.module').then(m => m.AgentWebModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
