import { NgModule } from '@angular/core';
import { SanitizeHtmlPipe } from 'src/app/pipe/sanitize-html.pipe';
import { NgSelectFormFieldControlDirective } from '../backend/common/ng-select.directive';

@NgModule({
  declarations: [SanitizeHtmlPipe, NgSelectFormFieldControlDirective],
  imports: [],
  exports: [SanitizeHtmlPipe, NgSelectFormFieldControlDirective]
})
export class SharedModule { }
